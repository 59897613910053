import { $regraEntidade, $regraAcao } from '@/custom-enum/regras-enum.js'

export default [
    {
        path: '/saude-mental/painel',
        name: 'saude-mental-painel',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloSaudeMental.painel,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Saúde Mental - Painel'
        },
        component: () =>
            import ('@/views/pages/dashboard/saude-mental/DashboardEmpresa.vue'),
    },
    {
      path: '/saude-mental/painel/tela-cheia',
      name: 'saude-mental-painel-tela-cheia',
      meta: {
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.moduloSaudeMental.painel,
          action: $regraAcao.visualizar,
          layout: 'full',
          title: 'Saúde Mental - Painel Tela Cheia'
      },
      component: () =>
          import ('@/views/pages/dashboard/saude-mental/DashboardEmpresaTelaCheia.vue'),
    },
    {
        path: '/saude-mental/respostas-compartilhadas',
        name: 'saude-mental-respostas-compartilhadas',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloSaudeMental.respostasCompartilhadas,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Saúde Mental - Respostas compartilhadas',
            protecao_vida: false,
        },
        component: () =>
            import ('@/views/pages/respostas-compartilhadas/RespostasCompartilhadas.vue'),
    },
    {
        path: '/saude-mental/respostas-compartilhadas-risco-a-vida',
        name: 'saude-mental-respostas-compartilhadas-risco-a-vida',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloSaudeMental.respostasCompartilhadasRiscoVida,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Saúde Mental - Respostas com Risco à vida',
            protecao_vida: true,
        },
        component: () =>
            import ('@/views/pages/respostas-compartilhadas/RespostasCompartilhadas.vue'),
    },
    {
        path: '/saude-mental/politica-de-privacidade',
        name: 'saude-mental-politica-de-privacidade',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloSaudeMental.politicaDePrivacidade,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Saúde Mental - Política de privacidade'
        },
        component: () =>
            import ('@/views/pages/saude-mental/PoliticaPrivacidadeSaudeMental.vue'),
    },
    {
        path: '/planeja-saude/politica-de-privacidade',
        name: 'planeja-saude-politica-de-privacidade',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloPlanejaSaude.politicaDePrivacidade,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Planeja Saúde - Política de privacidade'
        },
        component: () =>
            import ('@/views/pages/planeja-saude/PoliticaPrivacidadePlanejaSaude.vue'),
    },
    {
        path: '/planeja-saude/painel',
        name: 'painel-planeja-saude',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloPlanejaSaude.painel,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Planeja Saúde - Painel'
        },
        component: () =>
                import ('@/views/pages/dashboard/planeja-saude/DashboardPlanejaEmpresa.vue'),
    },
    {
        path: '/planeja-saude/seleciona-colaborador',
        name: 'planeja-saude-seleciona-colaborador-relatorio-individual',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloPlanejaSaude.relatorioIndividual,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Saúde Mental SESI - Selecionar Colaborador - Relatório Individual'
        },
        component: () =>
            import ('@/views/pages/planeja-saude/SelecionaColaboradorPlanejaSaude.vue'),
    },
    {
        path: '/planeja-saude/relatorio-individual',
        name: 'planeja-saude-relatorio-individual',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.moduloPlanejaSaude.relatorioIndividual,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Saúde Mental SESI - Relatório Individual'
        },
        component: () =>
            import ('@/views/pages/planeja-saude/RelatorioIndividualPlanejaSaude.vue'),
    },
    {
        path: '/planeja-saude/relatorio-cora',
        name: 'planeja-saude-relatorio-cora',
        meta: {
            resource: $regraEntidade.rotas.publica,
            layout: 'normal',
            title: 'Planeja Saúde - Relatório Individual'
        },
        component: () =>
            import ('@/views/pages/cora/Cora/PlanejaSaude/v3/Relatorio/relatorio.vue'),
    },
    {
        path: '/empresa-outros-produtos',
        name: 'usuario-empresa-listar-outros-produtos',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.outrosProdutos.empresa,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Outros Produtos'
        },
        component: () =>
            import ('@/views/pages/outros-produtos/usuario-empresa/listagemOutrosProdutos.vue'),
    },
    {
        path: '/sesi-outros-produtos-cadastrar',
        name: 'usuario-sesi-inserir-outros-produtos',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.outrosProdutos.sesi,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Inserir Outros Produtos',
            cadastrar: true
        },

        component: () =>
            import ('@/views/pages/outros-produtos/usuario-sesi/CadastrarOutrosProdutos.vue'),
    },
    {
        path: '/sesi-outros-produtos-editar',
        name: 'usuario-sesi-editar-outros-produtos',
        meta: {
            resource: $regraEntidade.rotas.protegida,
            subject: $regraEntidade.portal.outrosProdutos.sesi,
            action: $regraAcao.visualizar,
            layout: 'normal',
            title: 'Empresa - Editar Outros Produtos'
        },
        component: () => import ('@/views/pages/outros-produtos/usuario-sesi/EditarOutrosProdutos.vue'),
    },
    {
        path: '/power-bi/painel-unico-sesi-fit',
        name: 'painel-power-bi-sesi-fit',
        meta: { 
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.moduloSesiFIT.painel,
          action: $regraAcao.visualizar,
          layout: 'normal',
          title: 'Power BI - Painel - Sesi FIT', 
        },
        component: () =>
          import ('@/views/pages/sesi-fit-embedded/Dashboard.vue'),
    },
    {
        path: '/power-bi/painel-unico-planeja-saude',
        name: 'painel-power-bi-planeja-saude',
        meta: {
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.moduloPlanejaSaude.painelBI,
          action: $regraAcao.visualizar,
          layout: 'normal',
          title: 'Power BI - Painel - Planeja Saúde', 
        },
        component: () =>
          import ('@/views/pages/planeja-saude-bi-embedded/Dashboard.vue'),
    },
    {
        path: '/power-bi/painel-unico-academias-company',
        name: 'painel-power-bi-academias-company',
        meta: {
          resource: $regraEntidade.rotas.protegida,
          subject: $regraEntidade.moduloAcademias.painel,
          action: $regraAcao.visualizar,
          layout: 'normal',
          title: 'Power BI - Painel - Academias in Company',
        },
        component: () =>
          import ('@/views/pages/academias-company-bi-embedded/Dashboard.vue'),
    },
    {
      path: '/cursos-nr/sesi/painel',
      name: 'cursos-nr-sesi-painel',
      meta: {
        resource: $regraEntidade.rotas.protegida,
        subject: $regraEntidade.menuLateral.configuracoes.painelPorEmpresaCursosNR,
        action: $regraAcao.visualizar,
        layout: 'normal',
        title: 'Cursos NR - Painel - SESI',
      },
      component: () =>
        import ('@/views/pages/cursos-nr/Dashboard.vue'),
    },
    {
      path: '/cursos-nr/empresa/painel',
      name: 'cursos-nr-empresa-painel',
      meta: {
        resource: $regraEntidade.rotas.protegida,
        subject: $regraEntidade.moduloCursosNR.painelEmpresa,
        action: $regraAcao.visualizar,
        layout: 'normal',
        title: 'Cursos NR - Painel - Empresa',
      },
      component: () =>
        import ('@/views/pages/cursos-nr/Dashboard.vue'),
    },
]
