import rotasAPI from "@/router/rotasAPI";
import Echo from 'laravel-echo';

export default {
    retornaAtributoArray(array, campo) {
        return array.map(function(item) {
            return item[campo];
        });
    },

    verificaCPNJMatriz(cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, "");
        if (cnpj == "") return false;
        if (cnpj == null) return false;
        if (cnpj.length != 14) return false;
        return cnpj.substr(11, 1) == "1" ? true : false;
    },

    validarCNPJ(doc) {
        doc = this.strClear(doc)
        if (doc.length !== 14) return false
        if (!doc) return false
        if (doc.length !== 14) return false
            // Elimina docs invalidos conhecidos
        if (
            doc === "00000000000000" ||
            doc === "11111111111111" ||
            doc === "22222222222222" ||
            doc === "33333333333333" ||
            doc === "44444444444444" ||
            doc === "55555555555555" ||
            doc === "66666666666666" ||
            doc === "77777777777777" ||
            doc === "88888888888888" ||
            doc === "99999999999999"
        )
            return false;
        // Valida DVs
        let size = doc.length - 2
        let nums = doc.substring(0, size)
        const dig = doc.substring(size)
        let sum = 0
        let pos = size - 7
        for (let i = size; i >= 1; i--) {
            sum += nums.charAt(size - i) * pos--
                if (pos < 2) pos = 9
        }
        let res = sum % 11 < 2 ? 0 : 11 - sum % 11
        if (res != dig.charAt(0)) return false
        size += 1
        nums = doc.substring(0, size)
        sum = 0
        pos = size - 7
        for (let i = size; i >= 1; i--) {
            sum += nums.charAt(size - i) * pos--
                if (pos < 2) pos = 9
        }
        res = sum % 11 < 2 ? 0 : 11 - sum % 11
        return res == dig.charAt(1)
    },

    validarCPF(cpf) {
        cpf = this.strClear(cpf)
        if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        var result = true;
        [9, 10].forEach(function(j) {
            var soma = 0,
                r;
            cpf
                .split(/(?=)/)
                .splice(0, j)
                .forEach(function(e, i) {
                    soma += parseInt(e) * (j + 2 - (i + 1));
                });
            r = soma % 11;
            r = r < 2 ? 0 : 11 - r;
            if (r != cpf.substring(j, j + 1)) result = false;
        });
        return result;
    },

    preparaRotaComParametros(rota, substituir, parametro) {
        return rota.replace(substituir, parametro);
    },

    preparaRotaComMultiplosParametros(rota, valores = []) {
        for (var key in valores) {
            rota = this.preparaRotaComParametros(rota, key, valores[key]);
        }
        return rota;
    },

    retornaStringConcatenadaDeUmArrayMultiNivel(
        arrayMultiNivel,
        campo = "descricao",
        textoDefault = "Não informado"
    ) {
        var string = "";
        if (arrayMultiNivel.length > 0) {
            string = arrayMultiNivel
                .map(function(item) {
                    return item[campo];
                })
                .join(", ");
        }

        return string ? string : textoDefault;
    },

    variavelExiste(variavel) {
        return typeof variavel != "undefined";
    },

    urlSite(path = "") {
        return process.env.VUE_APP_APP_URL + path;
    },

    baixarArquivo(blob, filename) {
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
                var url = URL.createObjectURL(blob),
                    idUnico = new Date()
                    .toISOString()
                    .replaceAll(":", "-")
                    .replaceAll(".", "-"),
                    novoNomeArquivo = idUnico + "_" + filename;

                link.setAttribute("href", url);
                link.setAttribute("download", novoNomeArquivo);
                link.style.visibility = "hidden";

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },

    baixarArquivoCsv(conteudo, filename) {
        var blob = new Blob([conteudo], {
            type: "text/csv;charset=utf-8;",
        });
        this.baixarArquivo(blob, filename);
    },

    formataCnpj(cnpj) {
        return cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
        );
    },

    converteDataBancoParaHumano(data, truncarAno = false) {
        let dataTrun = data.slice(0, 10),
            ano = dataTrun.split("-")[0],
            mes = dataTrun.split("-")[1],
            dia = dataTrun.split("-")[2];

        if (truncarAno) {
            ano = ano.substr(-2);
        }

        return `${dia}/${mes}/${ano}`;
    },

    converteHoraBancoParaHumano(hora) {
        return hora.substr(0, 8);
    },

    converteDateTimeBancoParaHumano(datetime) {
        let data = this.converteDataBancoParaHumano((datetime.split(" ")[0])),
            hora = this.converteHoraBancoParaHumano((datetime.split(" ")[1]));
        return `${data} ${hora}`;
    },

    removeAcentos(valor) {
        return valor.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    validarEmail(email) {
        let re = /\S+@\S+\.\S+/;

        return re.test(email);
    },

    handlePluck(arr, element) {
        let newArr = [];

        arr.forEach((obj, _i) => {
            newArr.push(obj[element]);
        });

        return newArr;
    },

    handleConcatNoRepeat(arr1, arr2) {
        let r1 = arr1;
        let r2 = arr2;

        let onlyR1 = r1.filter(function(element) {
            if (r2.indexOf(element) === -1)
                return element;
        });

        let onlyR2 = r2.filter(function(element) {
            if (r1.indexOf(element) === -1)
                return element;
        });

        return onlyR1.concat(onlyR2);
    },

    PusherAuth(token) {
        window.Pusher = require('pusher-js');
        var RotaBroadcastingAuth = process.env.VUE_APP_API_URL + rotasAPI.BroadcastingAuth;
        window.Echo = new Echo({
            authEndpoint: RotaBroadcastingAuth,
            broadcaster: 'pusher',
            key: process.env.VUE_APP_PUSHER_APP_KEY,
            cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
            encrypted: true,
            auth: {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            },
        });
    },

    strClear(str) {
        return str.replace(/\D/g, '')
    },

    converteArrayAggParaString(array_agg) {
        array_agg = array_agg.replaceAll("{", "");
        array_agg = array_agg.replaceAll("}", "");
        array_agg = array_agg.replaceAll(",NULL", "");
        array_agg = array_agg.replaceAll("NULL", "");
        array_agg = array_agg.replaceAll(",", ", ");
        array_agg = array_agg.replaceAll('"', "");
        return array_agg;
    },

    converteArrayAggParaArray(array_agg) {
        return this.converteArrayAggParaString(array_agg).split(', ');

    },
    delay(ms = 1000) {
        return new Promise(resolve => setTimeout(resolve, ms));
    },

    findIndexInArray(array, objeto, campo) {
        return array.findIndex(x => x[campo] === objeto[campo]);
    },

    encontrarValorMaisAltaEmArray(array, propriedade) {
      let valorMaisAlto = array[0][propriedade];

      for (const element of array) {
        if (element[propriedade] > valorMaisAlto) {
          valorMaisAlto = element[propriedade];
        }
      }

      return valorMaisAlto;
    },

    removeHTMLTags(str) {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      return tmp.innerText;
    },

    truncateText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength - 3) + '...' : text;
    }
}
